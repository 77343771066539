import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String
  }
  connect() {
  }

  add() {
    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        "X-CSRF-Token": this.csrfToken()
      }
    }).then(resp => resp.text()).then(_resp => {
      this.element.classList.add("visually-hidden")
      window.dispatchEvent(
        new CustomEvent("PlanItemExerciseAdded")
      )
    })
  }

  csrfToken() {
    return document.querySelector("[name='csrf-token']").content
  }
}
