import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {"start": String, "length": Number };
  static targets = ["indicator", "time"];

  connect() {
    this.progress = 0
    this.startTime = Date.parse(this.startValue);
    this.endTime = this.startTime + (this.lengthValue * 1000);
    this.render = this.render.bind(this);
    this.interval = requestAnimationFrame(this.render)
  }

  render() {
    const diff = (this.endTime - Date.now()) / 1000.0;

    if (diff < -10) {
      this.element.remove();
      return;
    }

    const progress = diff / this.lengthValue;
    this.indicatorTarget.style = "width: " + Math.max(0, (progress * 100)) + "%";

    const timeInput = Math.max(0, diff)
    const minutes = timeInput / 60;
    const seconds = timeInput % 60;
    this.timeTarget.innerText = "" + Math.floor(minutes).toString().padStart(2, "0") + ":" + Math.floor(seconds).toString().padStart(2, "0");
    requestAnimationFrame(this.render);
  }
}
