import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
  }

  update() {
    fetch(this.urlValue).then(resp => resp.text()).then(resp => { this.element.innerHTML = resp })
  }

}
