import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["option", "input"]
  static values = {
    activeClass: String
  }

  selectValue(event) {
    this.inputTarget.value = event.params.option;
    this.optionTargets.forEach((element) => {
      element.classList.remove(this.activeClassValue);
    })
    event.target.classList.add(this.activeClassValue)
  }
}
