import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto';

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    this.canvas = document.createElement("canvas");
    this.element.appendChild(this.canvas);
    fetch(this.urlValue)
      .then(response => response.json())
      .then(json => {
        new Chart(this.canvas, json);
      })
  }
}
