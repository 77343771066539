import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"];
  connect() {
  }

  step(event) {
    event.preventDefault();
    const change = event.params.value;

    this.inputTarget.value = Math.max(parseInt(this.inputTarget.value) + change, this.inputTarget.min);
  }
}
