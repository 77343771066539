import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "form"];

  showForm(event) {
    event.preventDefault()
    this.buttonTarget.classList.add("visually-hidden")
    this.formTarget.classList.remove("visually-hidden")
  }
}
